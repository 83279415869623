<template>
  <v-app-bar
    app
    color="white"
    class="AppBar"
    elevation="0"
    style="z-index: 7 /* Default: 5, must be higher than z-index of .LicenseStatusBar, and lower than .TheNavigationDrawer's */"
  >
    <h1
      v-if="$scopedSlots.header"
      class="AppBar__header"
    >
      <slot name="header" />
    </h1>

    <slot />

    <v-spacer v-if="!noSpacer" />

    <div class="AppBar__actions">
      <slot name="actions" />
    </div>

    <AppBarControls
      :class="actionButtonsClass"
      class="AppBar__controls"
    />
  </v-app-bar>
</template>

<script>
import AppBarControls from '../components/AppBarControls'

export default {
  name: 'AppBarLayout',

  components: {
    AppBarControls,
  },

  props: {
    // class to apply margins to top right action buttons
    actionButtonsClass: { type: [String, Array, Object], default: 'ml-16' },

    noSpacer: { type: Boolean, default: false },
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
  },
}
</script>

<style lang="sass">
@import '../scss/variables'

.AppBar
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important

  .v-toolbar__content
    padding: 4px (44px - 12px) 0 44px
    border-bottom: 2px solid #E6E6F2

  &__header
    font-style: normal
    font-weight: 500
    font-size: 24px
    line-height: 32px
    letter-spacing: 0.005em
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__actions
    display: flex
    align-items: center
    margin-left: auto
</style>
